<template>
  <div class="content">
    <PageHeader :title="$t('departments.header')">
      <ul class="actions top-right">
        <li>
          <a
            v-modal="{ target: 'department-modal' }"
            href="javascript:void(0)"
            class="btn btn-fab btn-success"
            aria-expanded="false"
          >
            <i class="fas fa-plus text-light"></i>
          </a>
        </li>
      </ul>
    </PageHeader>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-row">
              <div class="col-lg-2 col-md-2 m-top-10">
                <input
                  class="form-control"
                  :placeholder="this.$t('generic-str.search')"
                  v-model="form.q"
                />
              </div>
                <div class="col-lg-2 col-md-2 m-top-10">
                  <div class="form-group">
                    <div class="input-group">
                      <div class>
                        <button class="btn btn-danger" @click="fetch(1)">
                          {{$t('generic-str.filter')}}
                        </button><!-- Filtrar -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <div
                  v-if="!departments.length && fetched"
                  class="text-center table-placeholder p-5"
                >
                  <i
                    class="far fa-building font-size-80"
                    v-tooltip.top="
                      'Crie seu primeiro departamento clicando em criar novo departamento.'
                    "
                  ></i>
                  <h5 class="card-title m-t-20">{{$t('departments.none')}}</h5><!-- Nenhum departamento cadastrado -->
                  <p>{{$t('departments.lbl-register')}}</p><!-- Cadastre seu primeiro departamento. -->
                </div>
                <table v-else-if="fetched" class="table">
                  <thead class="bg-light">
                    <tr>
                      <th class="p-l-20">{{$t('departments.data-infos.lbl-name')}}</th><!-- Nome -->
                      <th>{{$t('departments.data-infos.lbl-description')}}</th><!-- Descrição -->
                      <th>{{$t('departments.data-infos.lbl-status')}}</th><!-- Estado/Status -->
                      <!-- <th v-if="isAdmin"></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      role="button"
                      v-for="departament in departments"
                      :key="departament.id"
                      v-modal="{ target: 'department-modal', data: departament }"
                    >
                    <!-- <tr
                      role="button"
                      v-for="departament in departments"
                      :key="departament.id"
                    > -->
                      <td class="td-user-m">
                        {{ departament.name }}
                      </td>
                      <td>
                        {{ departament.description }}
                      </td>
                      <td>
                        <span class="badge badge-success" v-if="departament.active">
                          {{$t('departments.data-infos.lbl-active')}}
                        </span>
                        <span class="badge badge-danger" v-else>
                          {{$t('departments.data-infos.lbl-inactive')}}
                        </span>
                      </td>
                      <!-- <td v-if="isAdmin">
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + departament.id"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + departament.id"
                            x-placement="bottom-end">
                            <button
                              class="dropdown-item"
                              v-modal="{ target: 'department-modal', data: { remove: false, ...departament } }"
                            >{{$t('generic-str.edit')}}</button>
                            <button
                              class="dropdown-item"
                              v-modal="{ target: 'department-modal', data: { remove: true, ...departament } }"
                            >{{$t('generic-str.remove')}}</button>
                          </div>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
                <div v-else class="qt-block-ui" />
                <br />
                <pagination :lastPage="pages" :currentPage="currenPage" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <department-modal id="department-modal" @created="fetch(form.page)" @updated="fetch(form.page)"></department-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import DepartmentService from '@/services/department.service';
import PageHeader from '@/components/PageHeader.vue';
import DepartmentModal from '@/components/departments/DepartmentModal.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'Users',
  components: {
    PageHeader,
    DepartmentModal,
    Pagination,
  },
  computed: {
    isAdmin() {
      const role = this.$store.state.account.role;
      return role === 'admin' || role === 'owner';
    },
  },
  data() {
    return {
      fetched: false,
      form: {
        q: '',
        page: 1,
      },
      departments: [],
      pages: 1,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      DepartmentService.getDepartments(this.form).then(
        (response) => {
          this.departments = response.data;
          this.pages = response.last_page;
          this.currenPage = response.current_page;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>
<style scoped lang="scss">
.block-el {
  min-height: 300px;
}
tbody tr {
  &:hover {
    background-color: #fafafa; /* #f0f6ff */
  }
}
</style>
